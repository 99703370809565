export type LotteryDatum = { ratio: number; image: string };
export type LotteryData = LotteryDatum[];

export const useLottery =
  () =>
  (lotteryData: LotteryData): string => {
    const ratioSum: number = lotteryData.reduce(
      (sum, element) => sum + element.ratio,
      0,
    );
    const randomValue: number = ratioSum * Math.random();
    let ratioSubtotal: number = 0;
    for (const lotteryDatum of lotteryData) {
      ratioSubtotal += lotteryDatum.ratio;
      if (randomValue < ratioSubtotal) return lotteryDatum.image;
    }
  };
