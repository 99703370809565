import { RAD2DEG } from "three/src/math/MathUtils";
import { sin } from "../../../../../hooks/PolarPosition";

const randomize = (min, max) => Math.random() * (max - min) + min;

export const useRandomAngle = () => {
  const randomTheta = (minTheta = -60, maxTheta = 60) =>
    RAD2DEG * Math.asin(randomize(sin(minTheta), sin(maxTheta)));
  const randomPhi = (minPhi = -180, maxPhi = 180) => randomize(minPhi, maxPhi);
  return { randomTheta, randomPhi };
};
