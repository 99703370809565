import { Euler, Vector3 } from "three";
import { DEG2RAD } from "three/src/math/MathUtils";

const d2r = (d: number): number => DEG2RAD * d;

export const useAxes = (): [
  (farness: number, height: number, how_far_right: number) => Vector3,
  (pitch: number, yaw: number, roll: number) => Euler,
] => {
  const isIos: boolean = /iP(hone|[oa]d)/.test(navigator.userAgent);
  const vector3 = (
    farness: number,
    height: number,
    how_far_right: number,
  ): Vector3 => {
    return isIos
      ? new Vector3(how_far_right, height, -farness)
      : new Vector3(farness, height, how_far_right);
  };
  const euler = (pitch: number, yaw: number, roll: number): Euler => {
    return isIos
      ? new Euler(d2r(pitch), d2r(yaw), d2r(roll), "YXZ")
      : new Euler(d2r(pitch), d2r(yaw - 90), d2r(roll), "YXZ");
  };
  return [vector3, euler];
};
