import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MRRootView from "../MRRootView";
import { ApngObjects, apngUrl } from "../apng-test";
import { BigslotDecorator, BigslotObjects, bigslotUrl } from "../bigslot/20230715";
import { bigslotNoticeUrl, NoticeDecorator, NoticeObjects } from "../bigslot/20230805/notice";
import { BunkasaiObjects, bunkasaiUrl } from "../bunkasai";
import { EspoGachaObjects, espoGachaUrl } from "../espolada/20230827/gacha";
import { EspoTreasureObjects, espoTreasureUrl } from "../espolada/20230827/treasure";
import { OtaruDecorator, OtaruObjects, otaruUrl } from "../otaru/20230805";
import { PachinkoDemoDecorator, PachinkoDemoObjects, pachinkoDemoUrl } from "../pachinko/demo/20231005";
export default function PublicRoute() {
  return (
    <BrowserRouter>
      <Routes>
        {/* public route  */}
        {/* NOTE: nested routes won't work for graffiti */}
        <Route index element={<MRRootView />} />
        <Route path={apngUrl} element={<MRRootView graffiti={<ApngObjects />} />} />
        <Route path={bigslotUrl} element={<MRRootView decorator={<BigslotDecorator />} graffiti={<BigslotObjects />} />} />
        <Route path={bigslotNoticeUrl} element={<MRRootView decorator={<NoticeDecorator />} graffiti={<NoticeObjects />} />} />
        <Route path={bunkasaiUrl} element={<MRRootView graffiti={<BunkasaiObjects />} />} />
        <Route path={espoGachaUrl} element={<MRRootView graffiti={<EspoGachaObjects />} />} />
        <Route path={espoTreasureUrl} element={<MRRootView graffiti={<EspoTreasureObjects />} />} />
        <Route path={otaruUrl} element={<MRRootView decorator={<OtaruDecorator />} graffiti={<OtaruObjects />} />} />
        <Route path={pachinkoDemoUrl} element={<MRRootView decorator={<PachinkoDemoDecorator />} graffiti={<PachinkoDemoObjects />} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        {/* public route  */}

        {/* private route  */}
        {/* private route  */}
      </Routes>
    </BrowserRouter>
  );
}
