import { memo } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../../hooks/PolarPosition";
import { usePosition as useLocation } from "../../../hooks/Position";
import { useRandomPosition } from "../../../hooks/RandomPosition";

import a from "./images/a_center_location1.png";
import b from "./images/b_center_location2.png";
import c from "./images/c_center_location3.png";
import d from "./images/d_center_location4.png";
import e from "./images/e_center_location5.png";
import f from "./images/f_random.png";
import i from "./images/i_random.png";
import j from "./images/j_random.png";
import k from "./images/k_right_left.jpg";
import l from "./images/l_right_right.jpg";
import m from "./images/m_left.jpg";

const location1 = { latitude: 43.191307500855324, longitude: 141.00743702639005 };
const location2 = { latitude: 43.19477816719975, longitude: 141.0057582957381 };
const location3 = { latitude: 43.19572660116532, longitude: 141.00372399327316 };
const location4 = { latitude: 43.19550280533353, longitude: 141.00390489736975 };
const location5 = { latitude: 43.19434656484604, longitude: 141.0059366300781 };

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const randomPosition = useRandomPosition();
  const [_position, _getCurrent, _getCurrentSilent, get_in] = useLocation();
  return (
    <>
      {/* A center (location 1) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 0)} show={get_in(location1, 40)} image={a} w={1280} h={720} />

      {/* B center (location 2) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 0)} show={get_in(location2, 40)} image={b} w={1280} h={720} />

      {/* C center (location 3) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 0)} show={get_in(location3, 40)} image={c} w={1280} h={720} />

      {/* D center (location 4) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 0)} show={get_in(location4, 40)} image={d} w={1280} h={720} />

      {/* E center (location 5) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 0)} show={get_in(location5, 40)} image={e} w={1280} h={720} />

      {/* F random */}
      <SimpleImageAdsense {...randomPosition(100)} show image={f} w={1280} h={720} />

      {/* I random */}
      <SimpleImageAdsense {...randomPosition(80)} show image={i} w={1280} h={720} />

      {/* J random */}
      <SimpleImageAdsense {...randomPosition(75)} show image={j} w={1280} h={720} />

      {/* K right (left) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 83)} show image={k} w={1280} h={720} />

      {/* L right (right) */}
      <SimpleImageAdsense {...polarPosition(50, 0, 97)} show image={l} w={1280} h={720} />

      {/* M left */}
      <SimpleImageAdsense {...polarPosition(50, 0, -90)} show image={m} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
