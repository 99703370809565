import { memo, useState } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { useRandomPick } from "../../../hooks/RandomPick";

import box from "./images/i_gacha_box.png";
import p01 from "./images/i_gacha_prizes/prize_01.png";
import p02 from "./images/i_gacha_prizes/prize_02.png";
import p03 from "./images/i_gacha_prizes/prize_03.png";
import p04 from "./images/i_gacha_prizes/prize_04.png";
import p05 from "./images/i_gacha_prizes/prize_05.png";
import p06 from "./images/i_gacha_prizes/prize_06.png";
import p07 from "./images/i_gacha_prizes/prize_07.png";
import p08 from "./images/i_gacha_prizes/prize_08.png";
import p09 from "./images/i_gacha_prizes/prize_09.png";
import p10 from "./images/i_gacha_prizes/prize_10.png";
import p11 from "./images/i_gacha_prizes/prize_11.png";
import p12 from "./images/i_gacha_prizes/prize_12.png";
import p13 from "./images/i_gacha_prizes/prize_13.png";
import p14 from "./images/i_gacha_prizes/prize_14.png";
import p15 from "./images/i_gacha_prizes/prize_15.png";
import p16 from "./images/i_gacha_prizes/prize_16.png";
import p17 from "./images/i_gacha_prizes/prize_17.png";
import p18 from "./images/i_gacha_prizes/prize_18.png";
import p19 from "./images/i_gacha_prizes/prize_19.png";
import p20 from "./images/i_gacha_prizes/prize_20.png";
import p21 from "./images/i_gacha_prizes/prize_21.png";
import p22 from "./images/i_gacha_prizes/prize_22.png";
import p23 from "./images/i_gacha_prizes/prize_23.png";
import p24 from "./images/i_gacha_prizes/prize_24.png";
import p25 from "./images/i_gacha_prizes/prize_25.png";
import p26 from "./images/i_gacha_prizes/prize_26.png";
import p27 from "./images/i_gacha_prizes/prize_27.png";
import p28 from "./images/i_gacha_prizes/prize_28.png";
import p29 from "./images/i_gacha_prizes/prize_29.png";
import p30 from "./images/i_gacha_prizes/prize_30.png";
import p31 from "./images/i_gacha_prizes/prize_31.png";
import p32 from "./images/i_gacha_prizes/prize_32.png";
import p33 from "./images/i_gacha_prizes/prize_33.png";
import p34 from "./images/i_gacha_prizes/prize_34.png";
import p35 from "./images/i_gacha_prizes/prize_35.png";
import p36 from "./images/i_gacha_prizes/prize_36.png";
import p37 from "./images/i_gacha_prizes/prize_37.png";
import p38 from "./images/i_gacha_prizes/prize_38.png";
import p39 from "./images/i_gacha_prizes/prize_39.png";
import p40 from "./images/i_gacha_prizes/prize_40.png";
import p41 from "./images/i_gacha_prizes/prize_41.png";
import p42 from "./images/i_gacha_prizes/prize_42.png";
import p43 from "./images/i_gacha_prizes/prize_43.png";
import p44 from "./images/i_gacha_prizes/prize_44.png";
import p45 from "./images/i_gacha_prizes/prize_45.png";
import p46 from "./images/i_gacha_prizes/prize_46.png";
import p47 from "./images/i_gacha_prizes/prize_47.png";
import p48 from "./images/i_gacha_prizes/prize_48.png";
import p49 from "./images/i_gacha_prizes/prize_49.png";
import p50 from "./images/i_gacha_prizes/prize_50.png";
import p51 from "./images/i_gacha_prizes/prize_51.png";
import p52 from "./images/i_gacha_prizes/prize_52.png";
import p53 from "./images/i_gacha_prizes/prize_53.png";
import p54 from "./images/i_gacha_prizes/prize_54.png";
import p55 from "./images/i_gacha_prizes/prize_55.png";
import p56 from "./images/i_gacha_prizes/prize_56.png";
import p57 from "./images/i_gacha_prizes/prize_57.png";
import p58 from "./images/i_gacha_prizes/prize_58.png";
import p59 from "./images/i_gacha_prizes/prize_59.png";
import p60 from "./images/i_gacha_prizes/prize_60.png";
import p61 from "./images/i_gacha_prizes/prize_61.png";
import p62 from "./images/i_gacha_prizes/prize_62.png";
import p63 from "./images/i_gacha_prizes/prize_63.png";
import p64 from "./images/i_gacha_prizes/prize_64.png";
import p65 from "./images/i_gacha_prizes/prize_65.png";
import p66 from "./images/i_gacha_prizes/prize_66.png";
import p67 from "./images/i_gacha_prizes/prize_67.png";
import p68 from "./images/i_gacha_prizes/prize_68.png";
import p69 from "./images/i_gacha_prizes/prize_69.png";
import p70 from "./images/i_gacha_prizes/prize_70.png";
import p71 from "./images/i_gacha_prizes/prize_71.png";
import p72 from "./images/i_gacha_prizes/prize_72.png";
import p73 from "./images/i_gacha_prizes/prize_73.png";
import p74 from "./images/i_gacha_prizes/prize_74.png";
import p75 from "./images/i_gacha_prizes/prize_75.png";
import p76 from "./images/i_gacha_prizes/prize_76.png";
import p77 from "./images/i_gacha_prizes/prize_77.png";
import p78 from "./images/i_gacha_prizes/prize_78.png";
import p79 from "./images/i_gacha_prizes/prize_79.png";
import p80 from "./images/i_gacha_prizes/prize_80.png";
import p81 from "./images/i_gacha_prizes/prize_81.png";
import p82 from "./images/i_gacha_prizes/prize_82.png";
import p83 from "./images/i_gacha_prizes/prize_83.png";
import p84 from "./images/i_gacha_prizes/prize_84.png";
import p85 from "./images/i_gacha_prizes/prize_85.png";
import p86 from "./images/i_gacha_prizes/prize_86.png";
import p87 from "./images/i_gacha_prizes/prize_87.png";
import p88 from "./images/i_gacha_prizes/prize_88.png";
import p89 from "./images/i_gacha_prizes/prize_89.png";
import p90 from "./images/i_gacha_prizes/prize_90.png";
import p91 from "./images/i_gacha_prizes/prize_91.png";
import p92 from "./images/i_gacha_prizes/prize_92.png";
import p93 from "./images/i_gacha_prizes/prize_93.png";
import p94 from "./images/i_gacha_prizes/prize_94.png";
import p95 from "./images/i_gacha_prizes/prize_95.png";
import p96 from "./images/i_gacha_prizes/prize_96.png";
import p97 from "./images/i_gacha_prizes/prize_97.png";
import p98 from "./images/i_gacha_prizes/prize_98.png";

const prizes = [
  p01,
  p02,
  p03,
  p04,
  p05,
  p06,
  p07,
  p08,
  p09,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  p22,
  p23,
  p24,
  p25,
  p26,
  p27,
  p28,
  p29,
  p30,
  p31,
  p32,
  p33,
  p34,
  p35,
  p36,
  p37,
  p38,
  p39,
  p40,
  p41,
  p42,
  p43,
  p44,
  p45,
  p46,
  p47,
  p48,
  p49,
  p50,
  p51,
  p52,
  p53,
  p54,
  p55,
  p56,
  p57,
  p58,
  p59,
  p60,
  p61,
  p62,
  p63,
  p64,
  p65,
  p66,
  p67,
  p68,
  p69,
  p70,
  p71,
  p72,
  p73,
  p74,
  p75,
  p76,
  p77,
  p78,
  p79,
  p80,
  p81,
  p82,
  p83,
  p84,
  p85,
  p86,
  p87,
  p88,
  p89,
  p90,
  p91,
  p92,
  p93,
  p94,
  p95,
  p96,
  p97,
  p98,
];

const Gacha = (props) => {
  const [open, setOpen] = useState(false);
  const [prize, setPrize] = useState(null);
  const randomPick = useRandomPick();
  const onClick = () => {
    if (open) return;
    const randomPrize = randomPick(prizes);
    setPrize(randomPrize);
    setOpen(true);
  };
  return (
    <>
      {open ? (
        <SimpleImageAdsense {...props.position.prize} show image={prize} w={1280} h={720} />
      ) : (
        <SimpleImageAdsense {...props.position.box} show image={props.box ?? box} w={1280} h={720} onClick={onClick} />
      )}
    </>
  );
};

export default memo(Gacha);
