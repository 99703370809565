import { React, memo, useState, useCallback, useEffect } from "react";
import { Html } from "@react-three/drei";
import YouTube from "react-youtube";
import { useAxes } from "../../hooks/Axes";
import { usePosition } from "../../hooks/Position";

function YoutubeAdsense(props) {
  const [isLite, setIsLite] = useState(true);
  const [position, getCurrent, getCurrentSilent, get_in] = usePosition();
  const onClick = useCallback((e) => {
    setIsLite(!isLite);
  }, []);

  const on_ready = useCallback((e) => {
    console.log("ready : ", e);
    e.target.playVideo();
  }, []);
  const on_state_change = useCallback((e) => {
    console.log("state change : ", e);
  }, []);
  const on_error = useCallback((e) => {
    console.error("error : ", e);
  }, []);
  const opts = {
    height: props.h,
    width: props.w,
    playerVars: {
      muted: 1,
      playsinline: 1,
      autoplay: 1,
      controls: 1,
    },
  };
  const [v, e] = useAxes();
  const distance = (lat1, lng1, lat2, lng2) => {
    lat1 = (lat1 * Math.PI) / 180;
    lng1 = (lng1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;
    lng2 = (lng2 * Math.PI) / 180;

    const A = 6378137.0;
    const B = 6356752.31414;
    const e2 = (Math.pow(A, 2) - Math.pow(B, 2)) / Math.pow(A, 2);

    const N1 = A / Math.sqrt(1 - e2 * Math.pow(Math.sin(lat1), 2));
    const N2 = A / Math.sqrt(1 - e2 * Math.pow(Math.sin(lat2), 2));
    const N = (N1 + N2) / 2;
    const dx = N2 * Math.cos(lat2) * Math.cos(lng2) - N1 * Math.cos(lat1) * Math.cos(lng1);
    const dy = N2 * Math.cos(lat2) * Math.sin(lng2) - N1 * Math.cos(lat1) * Math.sin(lng1);
    const dz = N2 * (1 - e2) * Math.sin(lat2) - N1 * (1 - e2) * Math.sin(lat1);
    const Rn = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2) + Math.pow(dz, 2));

    const F = Math.asin(Rn / 2 / N);

    return 2 * N * F;
  };
  const entered = (puts, now_i_am_in, radius) => {
    if (!puts) return true;
    if (!now_i_am_in) return true;
    const r = radius || 10;
    console.log(
      puts,
      now_i_am_in,
      distance(puts.latitude, puts.longitude, now_i_am_in.latitude, now_i_am_in.longitude),
    );
    return distance(puts.latitude, puts.longitude, now_i_am_in.latitude, now_i_am_in.longitude) <= r;
  };
  return (
    <Html
      key={Math.random() * 1000000}
      rotation={e(props.pitch, props.yaw, props.roll)}
      position={v(props.farness, props.height, props.how_far_right)}
      wrapperClass={"will-change "}
      as="div"
      zIndexRange={[10000, 0]}
      center
      transform
      sprite={props.alwaysLookAtCamera}
      style={{
        display: `${props.show ? "inline" : "none"}`,
      }}
    >
      {isLite ? (
        <img
          src={`https://img.youtube.com/vi/${props.id}/maxresdefault.jpg`}
          width={props.w}
          height={props.h}
          className={`max-w-none max-h-none`}
          alt=""
          onClick={onClick}
        />
      ) : (
        <YouTube
          videoId={props.id}
          id={props.id}
          opts={opts}
          className={""}
          iframeClassName={""}
          title={""}
          onReady={on_ready}
          onError={on_error}
          onStateChange={on_state_change}
        />
      )}
    </Html>
  );
}
export default memo(YoutubeAdsense);
