import { memo, useState } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";
import { useLottery } from "../../../../hooks/Lottery";
import { usePolarPosition } from "../../../../hooks/PolarPosition";

import a from "./images/a.png";
import b1 from "./images/b1.png";
import b2 from "./images/b2.png";
import b3 from "./images/b3.png";
import b4 from "./images/b4.png";
import b5 from "./images/b5.png";

const b = [
  { ratio: 4, image: b1 },
  { ratio: 7, image: b2 },
  { ratio: 24, image: b3 },
  { ratio: 30, image: b4 },
  { ratio: 35, image: b5 },
];

const Objects = (props) => {
  const lottery = useLottery();
  const polarPosition = usePolarPosition();
  const [prize, setPrize] = useState(null);
  const handleClick = () => {
    if (prize !== null) return;
    setPrize(lottery(b));
  };
  return (
    <>
      {/* A: size L, center */}
      <SimpleImageAdsense {...polarPosition(prize === null ? 45 : 47, 0, 0)} show image={prize ?? a} w={1280} h={720} onClick={handleClick} />
    </>
  );
};

export default memo(Objects);
