import { Html } from "@react-three/drei";
import { useAxes } from "../../hooks/Axes";

function SimpleHtml(props) {
  const [v, e] = useAxes();
  return (
    <Html
      key={Math.random() * 1000000}
      rotation={e(props.pitch, props.yaw, props.roll)}
      position={v(props.farness, props.height, props.how_far_right)}
      wrapperClass={"will-change "}
      as="div"
      zIndexRange={[10000, 0]}
      center
      transform
      sprite={props.alwaysLookAtCamera}
      style={{ display: `${props.show ? "inline" : "none"}` }}
    >
      {props.children}
    </Html>
  );
}
export default SimpleHtml;
