import { memo, useState } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";
import YoutubeAdsense from "../../../adsense/YoutubeAdsense";
import GachaLegacy from "../../../bigslot/20230625/Gacha";
import Gacha from "../../../bigslot/20230715/Gacha";
import { usePolarPosition } from "../../../../hooks/PolarPosition";

import a from "./images/a.png";
import b from "./images/b.png";
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import d1 from "./images/d1.png";
import d2 from "./images/d2.jpeg";
import e from "./images/e.png";
import f from "./images/f.png";
import j from "./images/j.gif";
import k from "./images/k.gif";

import f01 from "../../../bigslot/20230715/images/e_heroine_prizes/e01.png";
import f02 from "../../../bigslot/20230715/images/e_heroine_prizes/e02.png";
import f03 from "../../../bigslot/20230715/images/e_heroine_prizes/e03.png";
import f04 from "../../../bigslot/20230715/images/e_heroine_prizes/e04.png";

const fPrizes = [f01, f02, f03, f04];
const iId = "SIE4et0hgmo";

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const [clickedC, setClickedC] = useState(false);
  const [clickedD, setClickedD] = useState(false);
  const handleClickC = () => {
    if (clickedC) return;
    setClickedC(true);
  };
  const handleClickD = () => {
    if (clickedD) return;
    setClickedD(true);
  };
  return (
    <>
      {/* A center > left */}
      <SimpleImageAdsense {...polarPosition(70, 0, -6)} show image={a} w={1280} h={720} />

      {/* B center > right */}
      <SimpleImageAdsense {...polarPosition(70, 0, 6)} show image={b} w={1280} h={720} />

      {/* C top > lower */}
      <SimpleImageAdsense {...polarPosition(50, 30, 0)} show image={clickedC ? c2 : c1} w={1280} h={720} onClick={handleClickC} />

      {/* D right > left */}
      <SimpleImageAdsense {...polarPosition(clickedD ? 90 : 60, 0, 90 - 6)} show image={clickedD ? d2 : d1} w={1280} h={720} onClick={handleClickD} />

      {/* E (Machine Gacha) left > left */}
      <GachaLegacy position={{ box: polarPosition(50, 0, -90 - 7), prize: polarPosition(50, 0, -90 - 7) }} box={e} />

      {/* F (Heroine Gacha) left > right */}
      <Gacha position={{ box: polarPosition(50, 0, -90 + 7), prize: polarPosition(65, 0, -90 + 7) }} box={f} prizes={fPrizes} />

      {/* I (YouTube video) back*/}
      <YoutubeAdsense {...polarPosition(150, 0, 180)} show id={iId} w={1280} h={720} />

      {/* J right > right > top */}
      <SimpleImageAdsense {...polarPosition(70, 4, 90 + 6)} show image={j} w={1280} h={720} />

      {/* K right > right > bottom */}
      <SimpleImageAdsense {...polarPosition(70, -4, 90 + 6)} show image={k} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
