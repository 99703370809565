import { React } from "react";
import { Html } from "@react-three/drei";
import { useAxes } from "../../hooks/Axes";
// import { usePosition} from '../../hooks/Position'

function SimpleImageAdsense(props) {
  // const [now_i_am_in, getCurrent, getCurrentSilent, get_in] = usePosition();
  const [v, e] = useAxes();
  return (
    <Html
      key={Math.random() * 1000000}
      rotation={e(props.pitch, props.yaw, props.roll)}
      position={v(props.farness, props.height, props.how_far_right)}
      wrapperClass={"will-change "}
      as="div"
      zIndexRange={[10000, 0]}
      center
      transform
      sprite={props.alwaysLookAtCamera}
      style={{
        display: `${
          // props.show && get_in(props.puts, props.radius)
          props.show ? "inline" : "none"
        }`,
      }}
    >
      {/* ÷<PopIn tension={3000} friccion={1} mass={1}> */}
      <a href={props.link}>
        <img
          src={props.image}
          width={props.w}
          height={props.h}
          className={`max-w-[${props.w}px] max-h-[${props.h}px]`}
          alt=""
          onClick={props.onClick}
        />
      </a>
      {/* </PopIn> */}
    </Html>
  );
}
export default SimpleImageAdsense;
