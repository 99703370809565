import { React, memo, useCallback, useState } from "react";

function NotificationForUser(props) {
  let [asking, setAsking] = useState(true);
  const on_click = useCallback((e) => {
    if (/iP(hone|(o|a)d)/.test(navigator.userAgent)) {
      DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === "granted") {
            console.log(e);
            // props.emitter.emit('granted', true);
          }
        })
        .catch((e) => {
          console.error(e);
          // props.emitter.emit("err-req-permission", e)
        });
      //} else {
      //    props.emitter.emit('granted', false);
    }
    setAsking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={
        "absolute bg-[#000000bb] w-[100vw] h-[100vh] z-[10000000] flex items-center " + (asking ? "" : "hidden")
      }
    >
      <div className="bg-white rounded-lg m-8 flex-auto">
        <div className="bg-white text-sm my-5 text-center">
          <h5> このアプリを利用するには、</h5>
          <h5>ジャイロセンサへのアクセスが必要です。</h5>
          <h3> 当サイトを引き続き利用される場合は</h3>
          <h3>「許可」を押してください。</h3>
        </div>
        <div className="bg-white text-sm my-2 p-3 text-center flex ">
          <div className="flex-1 p-2 m-1 rounded-lg bg-slate-400">
            <h3>キャンセル</h3>
          </div>
          <div className="flex-1 p-2 m-1 rounded-lg bg-cyan-400" onClick={on_click}>
            <h3>許可</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(NotificationForUser);
