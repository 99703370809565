import { RAD2DEG } from "three/src/math/MathUtils";
import { Position, sin, usePolarPosition } from "./PolarPosition";

export type RandomPosition = (
  distance?: number,
  minPhi?: number,
  maxPhi?: number,
  minTheta?: number,
  maxTheta?: number,
) => Position;

const randomize = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

export const useRandomPosition = (): RandomPosition => {
  const polarPosition = usePolarPosition();
  const randomPosition: RandomPosition = (
    distance: number = 200, // 視点からの距離
    minPhi: number = -180, // 正面から左方向に真後ろまで
    maxPhi: number = 180, // 正面から右方向に真後ろまで
    minTheta: number = -60, // 下に最大60度見下ろす
    maxTheta: number = 60, // 上に最大60度見上げる
  ): Position => {
    const theta: number =
      RAD2DEG * Math.asin(randomize(sin(minTheta), sin(maxTheta)));
    const phi: number = randomize(minPhi, maxPhi);
    return polarPosition(distance, theta, phi);
  };
  return randomPosition;
};
