import { memo } from "react";

import footer from "./images/a_footer.png";
import header from "./images/b_header.png";

const headerHref = "https://www.p-world.co.jp/_machine/dedama.cgi?hall_id=002021&type=slot";

const Decorator = (props) => {
  return (
    <>
      {/* B header */}
      <div className="absolute top z-[3000000] inset-x-0 m-auto flex justify-top">
        <a href={headerHref} target="_blank" rel="noopener noreferrer">
          <img src={header} alt="" className="w-full" />
        </a>
      </div>

      {/* A footer */}
      <div className="absolute bottom z-[3000000] inset-x-0 bottom-0 m-auto flex justify-bottom pointer-events-none">
        <img src={footer} alt="" className="w-full" />
      </div>
    </>
  );
};

export default memo(Decorator);
