import { memo, useState } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { useRandomPick } from "../../../hooks/RandomPick";

const Gacha = (props) => {
  const [prize, setPrize] = useState(null);
  const randomPick = useRandomPick();
  const onClick = () => {
    if (prize !== null) return;
    const randomPrize = randomPick(props.prizes);
    setPrize(randomPrize);
  };
  return (
    <>
      {prize === null ? (
        <SimpleImageAdsense {...props.position.box} show image={props.box} w={1280} h={720} onClick={onClick} />
      ) : (
        <SimpleImageAdsense {...props.position.prize} show image={prize} w={1280} h={720} />
      )}
    </>
  );
};

export default memo(Gacha);
