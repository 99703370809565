import { memo } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../../../hooks/PolarPosition";
import Gacha from "../../20230625/Gacha";

import c from "./images/c_left_top.png";
import d from "./images/d_left_bottom.png";
import e from "./images/e_right_top.jpeg";
import f from "./images/f_right_bottom.jpeg";

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  return (
    <>
      {/* C left top */}
      <SimpleImageAdsense {...polarPosition(45, 8, -90)} show image={c} w={1280} h={720} />

      {/* D left bottom */}
      <SimpleImageAdsense {...polarPosition(55, -8, -90)} show image={d} w={1280} h={720} />

      {/* E right top */}
      <SimpleImageAdsense {...polarPosition(55, 8, 90)} show image={e} w={1280} h={720} />

      {/* F right bottom */}
      <SimpleImageAdsense {...polarPosition(45, -8, 90)} show image={f} w={1280} h={720} />

      {/* G Gacha (20230615) */}
      <Gacha position={{ box: polarPosition(40, 0, 0), prize: polarPosition(50, 0, 0) }} />
    </>
  );
};

export default memo(Objects);
