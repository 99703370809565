import { DEG2RAD } from "three/src/math/MathUtils";

export const cos = (d: number): number => Math.cos(DEG2RAD * d);
export const sin = (d: number): number => Math.sin(DEG2RAD * d);

export type Position = {
  farness: number;
  height: number;
  how_far_right: number;
  pitch: number;
  yaw: number;
  roll: number;
};
export type PolarPosition = (
  distance: number,
  theta: number,
  phi: number,
) => Position;

export const usePolarPosition = (): PolarPosition => {
  const polarPosition: PolarPosition = (
    distance: number, // 視点からの距離
    theta: number, // 水平面から見上げる角度 (見下ろす場合は負) -90°〜90°
    phi: number, // 正面から右に向く角度 (左方向は負) -180°〜180°
  ): Position => ({
    farness: distance * cos(theta) * cos(phi),
    height: distance * sin(theta),
    how_far_right: distance * cos(theta) * sin(phi),
    pitch: theta,
    yaw: -phi,
    roll: 0,
  });
  return polarPosition;
};
