import { Fragment, memo } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { useRandomPosition } from "../../../hooks/RandomPosition";
import { usePolarPosition } from "../../../hooks/PolarPosition";
import GachaLegacy from "../20230625/Gacha";
import Gacha from "./Gacha";

import c01 from "./images/c_random/c01.png";
import c02 from "./images/c_random/c02.png";
import c03 from "./images/c_random/c03.png";
import c04 from "./images/c_random/c04.png";
import c05 from "./images/c_random/c05.png";
import c06 from "./images/c_random/c06.png";
import c07 from "./images/c_random/c07.png";
import c08 from "./images/c_random/c08.png";
import c09 from "./images/c_random/c09.png";
import c10 from "./images/c_random/c10.png";
import eBox from "./images/e_heroine_box.png";
import e01 from "./images/e_heroine_prizes/e01.png";
import e02 from "./images/e_heroine_prizes/e02.png";
import e03 from "./images/e_heroine_prizes/e03.png";
import e04 from "./images/e_heroine_prizes/e04.png";
import fBox from "./images/f_number_box.png";
import f01 from "./images/f_number_prizes/f01.png";
import f02 from "./images/f_number_prizes/f02.png";
import f03 from "./images/f_number_prizes/f03.png";
import f04 from "./images/f_number_prizes/f04.png";
import f05 from "./images/f_number_prizes/f05.png";
import f06 from "./images/f_number_prizes/f06.png";
import f07 from "./images/f_number_prizes/f07.png";
import f08 from "./images/f_number_prizes/f08.png";
import f09 from "./images/f_number_prizes/f09.png";
import g from "./images/g_left.jpeg";
import h from "./images/h_right.jpeg";

const C_NUMBER = 50;
const ePrizes = [e01, e02, e03, e04];
const fPrizes = [f01, f02, f03, f04, f05, f06, f07, f08, f09];

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const randomPosition = useRandomPosition();
  return (
    <>
      {/* C random */}
      {[...Array(C_NUMBER)].map((_, i) => (
        <Fragment key={i}>
          <SimpleImageAdsense {...randomPosition(120)} show image={c01} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c02} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c03} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(120)} show image={c04} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c05} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c06} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c07} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c08} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(120)} show image={c09} w={1280} h={720} />
          <SimpleImageAdsense {...randomPosition(150)} show image={c10} w={1280} h={720} />
        </Fragment>
      ))}

      {/* D Gacha (20230615) */}
      <GachaLegacy position={{ box: polarPosition(40, 0, 0), prize: polarPosition(50, 0, 0) }} />

      {/* E Heroine Gacha */}
      <Gacha position={{ box: polarPosition(50, -12, -7), prize: polarPosition(60, -12, -7) }} box={eBox} prizes={ePrizes} />

      {/* F Machine Number Gacha */}
      <Gacha position={{ box: polarPosition(60, -12, 7), prize: polarPosition(50, -12, 7) }} box={fBox} prizes={fPrizes} />

      {/* G left */}
      <SimpleImageAdsense {...polarPosition(35, 0, -90)} show image={g} w={1280} h={720} />

      {/* H right */}
      <SimpleImageAdsense {...polarPosition(40, 0, 90)} show image={h} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
