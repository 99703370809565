import { memo } from "react";

import footer from "./images/a_footer.png";
import header from "./images/b_header.png";

const Decorator = (props) => {
  return (
    <>
      {/* B header */}
      <div className="absolute top z-[3000000] inset-x-0 m-auto flex justify-top pointer-events-none">
        <img src={header} alt="" className="w-full" />
      </div>

      {/* A footer */}
      <div className="absolute bottom z-[3000000] inset-x-0 bottom-0 m-auto flex justify-bottom pointer-events-none">
        <img src={footer} alt="" className="w-full" />
      </div>
    </>
  );
};

export default memo(Decorator);
